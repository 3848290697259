<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="perPageOptions"
              class="per-page-selector d-inline-block mx-50"
            />
            <label />
          </b-col>

          <b-col
            cols="6"
            class="mt-50 d-flex align-items-end justify-content-end"
          >

            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="بحث..."
            />

            <b-button

              variant="primary"
              class="mr-1"
              @click="save"
            >
              تصدير الى ملف اكسل
            </b-button>
          </b-col>

          <!-- Search -->
        </b-row>

      </div>

      <b-table
        id="sampletable"
        ref="refCourseListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="coursesList"
        striped
        show-empty
        empty-text="  يتم جلب البيانات"
      >
        <!-- Column: Status -->

        <!-- Column: Actions -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              عرض {{ dataMeta.from }} الى {{ dataMeta.to }} من {{ dataMeta.of }} المدخلات
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,

  BPagination,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, reactive, computed,
} from '@vue/composition-api'
import Vue from 'vue'
import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
   
    BPagination,

    vSelect,
  },
  setup() {
    const coursesList = ref([])
    const meta = reactive({})
    const categoriesList = ref([])
    const perPageOptions = [10, 25, 50, 100, 200]
    const perPage = ref(10)
    const searchQuery = ref('')
    const totalCategories = ref(0)
    const currentPage = ref(1)
    const refCourseListTable = ref('')
    watch([currentPage, perPage, searchQuery], () => {
      console.log('wats')
      GetAllMail()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    const GetAllMail = () => {
      store.dispatch('blog/GetAllMail', {
        'filter[search]': searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
      }).then(response => {
        console.log('GetAllMail', response.data.meta.total)
        coursesList.value = response.data.data
        totalCategories.value = response.data.meta.total
        console.log('totalCategories', totalCategories)
      })
    }
    GetAllMail()
    const DeleteCourse = id => {
      Vue.swal({
        icon: 'warning',
        title: 'هل انت متاكد من الحذف ?',
        confirmButtonText: 'نعم',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('courses/DeleteCourse', id).then(response => {
            GetAllMail()
            Vue.swal({
              title: 'تم الحذف  ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    }
    const save = () => {
      const wb = XLSX.utils.table_to_book(document.getElementById('sampletable'))
      XLSX.writeFile(wb, 'mails.xlsx')
    }
    const tableColumns = [
      { key: 'id', label: 'الرقم' },

      { key: 'type', label: 'النوع' },
      { key: 'email', label: 'الايميل' },
      { key: 'course', label: 'الدورة' },
      { key: 'date', label: 'التاريخ' },
      { key: "course_city", label: "مدينة الدورة" },
      { key: "course_price", label: "سعر الدورة" },
      { key: "course_date", label: "تاريخ الدورة" },
      { key: "mobile", label: "الجوال" },
    
    ]

    return {
      tableColumns,
      coursesList,
      DeleteCourse,
      meta,
      refCourseListTable,
      save,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      dataMeta,
      searchQuery,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
